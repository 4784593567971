import React, { useState } from 'react';
import styled from 'styled-components';
import Headroom from 'react-headroom';
import { Link } from 'gatsby';
import Hamburger from 'hamburger-react';
import Navigation from './navigation';
import LogoImage from '../images/logo.svg';

const Header = () => {
  const [isOpen, setOpen] = useState(false);

  return (
    <Headroom>
      <StyledHeader>
        <Logo id="logo">
          <Link to="/">
            <LogoImage aria-label="AMVI A.C." />
          </Link>
        </Logo>
        <Desktop>
          <Navigation />
        </Desktop>
        <Mobile>
          <StyledHamburger>
            <Hamburger
              toggled={isOpen}
              toggle={setOpen}
              aria-label="Navegación"
            />
          </StyledHamburger>
          {isOpen && (
            <Overlay>
              <Navigation />
            </Overlay>
          )}
        </Mobile>
      </StyledHeader>
    </Headroom>
  );
};

export default Header;

const StyledHeader = styled.header`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1rem 3%;
  background: #fff;
  border-bottom: 1px solid #eee;
`;

const Logo = styled.div`
  width: 225px;
  max-width: 90%;

  @media screen and (max-width: 768px) {
    width: 150px;
  }
`;

const Desktop = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const Mobile = styled.div`
  display: none;

  @media screen and (max-width: 768px) {
    display: block;
  }
`;

const StyledHamburger = styled.div`
  position: fixed;
  top: 1rem;
  right: 1rem;
  z-index: 100;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 50%;
  padding: 0.5rem;
`;

const Overlay = styled.div`
  background: rgba(255, 255, 255, 0.95);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
`;
