import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Container = ({ children }) => (
  <StyledContainer>{children}</StyledContainer>
);

Container.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Container;

const StyledContainer = styled.div`
  width: 1140px;
  max-width: 94%;
  margin: 0 auto;
`;
