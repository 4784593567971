import React from 'react';
import styled from 'styled-components';

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 -1rem 1rem;

  &.reverse {
    flex-direction: row-reverse;
  }
`;

export const Column = styled.div`
  min-width: 280px;
  flex-grow: 1;
  text-align: left;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem;

  &.quarter {
    width: 25%;
  }
  &.third {
    width: 33.333%;
  }
  &.two-third {
    width: 66.667%;
  }
  &.half {
    width: 50%;
  }
`;
