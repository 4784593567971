import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import {
  IoLogoFacebook,
  IoLogoInstagram,
  IoLogoTwitter,
  IoLogoYoutube,
  IoIosCall,
  IoIosMail,
  IoMdPin,
} from 'react-icons/io';
import Container from './container';
import { Row, Column } from './grid';

const Footer = () => (
  <StyledFooter>
    <Container>
      <Row>
        <Column>
          <h2>Navegación</h2>
          <Menu>
            <li>
              <Link to="/nosotros">Nosotros</Link>
            </li>
            <li>
              <Link to="/objetivos">Objetivos</Link>
            </li>
            <li>
              <Link to="/programas">Programas</Link>
            </li>
            <li>
              <Link to="/necesitas-ayuda">¿Necesitas Ayuda?</Link>
            </li>
            <li>
              <Link to="/quiero-ayudar">Quiero Ayudar</Link>
            </li>
            <li>
              <Link to="/contacto">Contacto</Link>
            </li>
          </Menu>
        </Column>
        <Column>
          <h2>Contacto</h2>
          <p>
            <IoIosMail /> <a href="mailto:hola@amvi.org.mx">hola@amvi.org.uk</a>
            <br />
            <IoIosCall /> <a href="tel:+527223966280">01 722 573 5475</a>
            <br />
            <IoMdPin /> 6-21-102 Valle de Cuitzeo
            <br />
            Delegación Capultitlán
            <br />
            50260 Toluca de Lerdo
            <br />
            Estado de México
            <br />
            México
          </p>
        </Column>
        <Column />
      </Row>
    </Container>
    <Socials>
      <a
        href="https://www.facebook.com/amamosvivirac/"
        target="_blank"
        rel="noreferrer"
      >
        <IoLogoFacebook aria-label="Facebook" />
      </a>
      <a
        href="https://twitter.com/fundacionamvi"
        target="_blank"
        rel="noreferrer"
      >
        <IoLogoTwitter aria-label="Twitter" />
      </a>
      <a
        href="https://www.instagram.com/amamos_vivir_cada_dia_/"
        target="_blank"
        rel="noreferrer"
      >
        <IoLogoInstagram aria-label="Instagram" />
      </a>
      <a
        href="https://www.youtube.com/channel/UCzTbSbALdNsss-0BtxSWZ0A"
        target="_blank"
        rel="noreferrer"
      >
        <IoLogoYoutube aria-label="Youtube" />
      </a>
    </Socials>
    © {new Date().getFullYear()} AMVI, Amamos Vivir Cada Día A.C.
  </StyledFooter>
);

export default Footer;

const StyledFooter = styled.footer`
  text-align: center;
  padding: 1rem;
  background: #3678af;
  color: #fff;
  font-size: 0.9rem;
  margin-top: 3rem;

  h2 {
    font-size: 1.25rem;
    margin-bottom: 0.5rem;
  }

  a {
    color: rgba(255, 255, 255, 0.5);

    &:hover {
      color: #fff;
    }
  }
`;

const Socials = styled.div`
  padding: 0.5rem;
  text-align: center;

  a {
    font-size: 1.5rem;
    margin: 0 0.5rem;
  }
`;

export const Menu = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  list-style: none;
  padding: 0;
  margin: 0;
`;
