import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

interface IProps {
  description?: string;
  lang?: string;
  meta?: any;
  keywords?: string[] | undefined;
  title: string;
}

const SeoHelmet = ({ description, lang, meta, title, keywords }: IProps) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  );

  const titleTemplate = `${
    site.siteMetadata.title
  } - Asociación sin fines de lucro${title !== 'Casa' ? ' » %s' : ''}`;

  const metaDescription = description || site.siteMetadata.description;
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={titleTemplate}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: `author`,
          content: `Jacob Herper`,
        },
        {
          property: `og:title`,
          content: titleTemplate,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          name: `og:image`,
          content: `https://www.amvi.org.mx/amvi.jpeg`,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:url`,
          content: `https://www.amvi.org.mx/`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: 'AMVI A.C.',
        },
        {
          name: `twitter:image`,
          content: `https://www.amvi.org.mx/amvi.jpeg`,
        },
        {
          name: `twitter:title`,
          content: titleTemplate,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ]
        .concat(
          keywords && keywords.length > 0
            ? {
                name: `keywords`,
                content: keywords.join(`, `),
              }
            : []
        )
        .concat(meta)}
      script={[
        {
          type: `application/ld+json`,
          innerHTML: `{"@context":"http://schema.org","@type":"WebSite","name":"Amamos Vivir Cada Día A.C.","alternateName":"AMVI A.C.","url":"https://www.amvi.org.mx/","description":"AMAMOS VIVIR CADA DÍA, es una asociación sin fines de lucro, que nace del amor a la humanidad, creada para ofrecer ayuda integral a jovenes y niños que padecen cualquier enfermedad crónica degenerativa en edades de 0 a 25 años, asi como a sus familias.","image":"https://www.amvi.org.mx/amvi.jpeg"}`,
        },
        {
          type: `application/ld+json`,
          innerHTML: `{"@context": "https://schema.org","@type": "NGO","name": "Amamos Vivir Cada Día A.C.","url": "https://www.amvi.org.mx/","logo": "https://www.amvi.org.mx/logo.svg","sameAs":["https://www.facebook.com/amamosvivirac/", "https://twitter.com/fundacionamvi", "https://www.instagram.com/amamos_vivir_cada_dia_/", "https://www.youtube.com/channel/UCzTbSbALdNsss-0BtxSWZ0A"],"email": "hola@amvi.org.mx","telephone": "+52 722 573 5475","legalName": "AMVI, Amamos Vivir Cada Día A.C.","location":{"@type": "PostalAddress","streetAddress": "6-21-102 Valle de Cuitzeo, Delegación Capultitlán","postalCode": "50260","addressLocality": "Toluca de Lerdo","addressRegion": "Estado de México","addressCountry": "Mexico"}}`,
        },
      ]}
    />
  );
};

SeoHelmet.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: [],
  description: ``,
};

SeoHelmet.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
};

export default SeoHelmet;
